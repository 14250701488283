<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask" @click="hideModal()">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop="doNothing()">
          <div style="display: flex; align-items: center;">
            <img v-if="icon" class="dialog_component_icon" v-lazy-load :data-src="icon" alt="icon">
            <h2 :class="{'open': showModal}" class="dialog_title">
              {{ title }}
            </h2>
            <img v-if="showCloseButton" class="close_button" v-lazy-load data-src="/icons/close.svg" @click.stop="hideModal()" alt="icon">
          </div>
          <div class="devider" />
          <template v-if="differentAddresses.length > 0">
            <div class="message">
              {{ message }}
            </div>
            <div
              v-for="(order, index) in aggregateAddresses"
              :key="index"
              class="address_grid_item pointer"
              @click.stop="setSelectedAddress(order)"
            >
              <vs-radio
                v-model="selectedAddress"
                :vs-name="'selected_address'"
                :vs-value="order"
              />
              <div>
                <div class="name">
                  <span>{{ order.deliverFullName }}</span>
                  {{ order.deliverStreet + ', ' + order.deliverCity + ', ' + order.deliverState + ', ' +
                    order.deliverZipCode }}
                </div>
                <div>{{ order.deliverPhone + ', ' + order.deliverEmail }}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="message" v-html="message" />
          </template>
          <div class="modal-footer">
            <div class="footer">
              <div
                v-if="positiveButton"
                :class="{'margin_right' : negativeButton, 'disabled': isPositiveButtonDisabled}"
                class="pointer gotit_button"
                @click.stop="onPositiveButtonAction()"
              >
                <div>
                  {{ positiveButton }}
                </div>
              </div>
              <div v-if="negativeButton" class="pointer gotit_button" @click.stop="onNegativeButtonAction()">
                <div>
                  {{ negativeButton }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DialogComponent',
  data () {
    return {
      showModal: false,
      cancelable: true,
      showCloseButton: false,
      showMenuOnClose: false,
      message: '',
      positiveButton: '',
      selectedAddress: null,
      differentAddresses: [],
      positiveButtonAction: () => {
      },
      negativeButton: '',
      negativeButtonAction: () => {
      }
    }
  },
  computed: {
    aggregateAddresses () {
      const aggregateArray = []
      const differentMap = {}
      this.differentAddresses.forEach((order) => {
        const deliverFirstName = order.deliverFirstName
        const deliverLastName = order.deliverLastName
        const deliverCity = order.deliverCity
        const deliverStreet = order.deliverStreet
        const deliverState = order.deliverState
        const deliverCountry = order.deliverCountry
        const deliverZipCode = order.deliverZipCode
        const phone = order.deliverPhone
        const email = order.deliverEmail
        const json = {
          deliverFirstName,
          deliverLastName,
          deliverCity,
          deliverStreet,
          deliverState,
          deliverCountry,
          deliverZipCode,
          phone,
          email
        }
        const address = JSON.stringify(json)
        if (!differentMap[address]) {
          differentMap[address] = 1
          aggregateArray.push(order)
        } else {
          differentMap[address]++
        }
      })
      return aggregateArray
    },
    isPositiveButtonDisabled () {
      if (this.differentAddresses.length > 0) {
        return this.selectedAddress === null
      } else {
        return false
      }
    }
  },
  mounted () {
    if (!this.registered) {
      this.registered = true
      this.$root.$on('show_dialog', (meta) => {
        this.selectedAddress = null
        this.showCloseButton = meta.showCloseButton || false
        this.showMenuOnClose = meta.showMenuOnClose || false
        this.icon = meta.icon || null
        this.title = meta.title || null
        this.message = meta.message || null
        this.differentAddresses = meta.differentAddresses || []
        // this.cancelable = meta.cancelable
        this.positiveButton = meta.positiveButton || null
        this.positiveButtonAction = meta.positiveButtonAction || null
        this.negativeButton = meta.negativeButton || null
        this.negativeButtonAction = meta.negativeButtonAction || null
        this.showModal = true
      })
      this.$emit('dialog_ready')
    }
  },
  methods: {
    isPositiveButtonDisabledFnc () {
      if (this.differentAddresses.length > 0) {
        return this.selectedAddress === null
      } else {
        return false
      }
    },
    hideModal () {
      if (this.cancelable) {
        this.showModal = false
        if (this.showMenuOnClose) {
          this.$root.$emit('show_nivigation_drower')
        }
      }
    },
    doNothing () {

    },
    onPositiveButtonAction () {
      if (!this.isPositiveButtonDisabledFnc()) {
        if (this.differentAddresses.length > 0) {
          this.positiveButtonAction(this.selectedAddress)
        } else {
          this.positiveButtonAction()
        }
        this.showModal = false
      }
    },
    onNegativeButtonAction () {
      this.negativeButtonAction()
      this.showModal = false
    },
    setSelectedAddress (address) {
      this.selectedAddress = address
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9999998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .modal-container {
    display: inline-block;
    /*width: 410px;*/
    margin: 0px auto;
    /*font-family: 'HKGroteskRegular', serif;*/
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .message {
    margin-top: 1em;
    margin-bottom: 1em;
    word-wrap: break-word;
  }

  .close_button {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(17deg) brightness(0%) contrast(100%);
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footer > div {
    flex-grow: 1;
    text-align: center !important;
  }

  .margin_right {
    margin-right: 1em;
  }

  .dialog_title {
    width: 0;
  }

  .dialog_title.open {
    width: 100%;
    -webkit-transition: width 2s; /* Safari prior 6.1 */
    transition: width 2s;
  }

  .address_grid_item {
    margin-right: 1em;
    padding: 1em;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
  }

  .name {
    font-weight: 700;
    color: #333;
  }

  .mobile_design .name {
    max-width: 90%;
  }

  .disabled {
    opacity: 0.5;
    cursor: unset;
  }

  .dialog_component_icon {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 1em;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    /*display: block;*/
  }
</style>
