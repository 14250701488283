<template>
  <transition v-if="showModal" name="modal">
    <div @click.stop="showModal = false" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="main">
            <div class="users_title">
              {{ title }}
            </div>
            <div v-for="(user, index) in users" :key="user.id" @click.stop="nothing()" class="user">
              <div class="user_image"><img class="user_image" v-lazy-load :data-src="user.profilePhotoThumbnail || user.image || 'https://s3.amazonaws.com/app.gotit.images/defult_pic.png'"></div>
              <div class="username">
                <div class="usr pointer" @click.stop="onUserClick(user)">{{user.username.substring(0, 30)}}</div>
                <div class="name">{{user.name.substring(0, 30)}}</div>
              </div>
              <div class="gotit_button follow_button" @click="onFollowClick(user, index)">
                {{ user.extra.isFollowed === 'true' ? 'Following' : 'Follow' }}
              </div>
            </div>
            <button v-if="!noMoreScroll && !loading" @click.stop="fetchNextPage()">
              Load More
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import utils from '~/mixins/utils'

export default {
  name: 'UsersDialogComponent',
  mixins: [utils],
  data () {
    return {
      showModal: false,
      loading: false,
      title: 'GOTit',
      skip: 0,
      filter: '',
      user: {},
      skipCounter: 0,
      limit: 20,
      users: [],
      noMoreScroll: false
    }
  },
  mounted () {
    this.$root.$on('show_users_dialog', async (entity, filter) => {
      this.skip = 0
      this.skipCounter = 0
      this.filter = filter
      this.loading = true
      this.user = entity
      this.users = []
      this.noMoreScroll = false
      this.showModal = true
      this.title = 'GOTit'
      switch (filter) {
        case 'WANTERS':
          this.title = 'WANTit'
          break
        case 'LIKERS':
          this.title = 'LIKERS'
          break
        case 'FOLLOWERS':
          this.title = 'FOLLOWERS'
          break
        case 'FOLLOWING':
          this.title = 'FOLLOWING'
          break
      }
      this.users = await this.$store.dispatch('api/usersGet', {
        filter,
        filterParam: this.user.id || this.user.id,
        limit: this.limit,
        skip: this.skip
      })
      this.loading = false
      if (this.users.length < this.limit) {
        this.noMoreScroll = true
      }
    })
  },
  methods: {
    onUserClick (user) {
      if (user.store) {
        this.$router.push({
          path: '/stores/' + this.encrypt(user.id)
        })
      } else {
        this.$router.push({
          path: '/users/' + this.encrypt(user.id)
        })
      }
    },
    getCurrentUser () {
      return this.$store.state.localStorage.currentUser
    },
    async onFollowClick (user, index) {
      let isFollowed = 'true'
      if (user.extra.isFollowed === 'true') {
        isFollowed = 'false'
        user.extra.isFollowed = 'true'
      } else {
        user.extra.isFollowed = 'false'
      }
      const params = {
        id: user.id,
        typeParam: isFollowed,
        type: 'FOLLOW'
      }
      user = await this.$store.dispatch('api/userExecute', params)
      this.users.splice(index, 1, user)
      this.$emit('onFollowChanged', user)
      this.$store.dispatch('api/followEvent', {
        isFollowed
      }).then().catch()
    },
    nothing () {
    },
    async fetchNextPage () {
      if (!this.loading && !this.noMoreScroll) {
        this.loading = true
        this.skipCounter++
        this.skip = this.limit * this.skipCounter
        this.loading = true
        const users = await this.$store.dispatch('api/usersGet', {
          filter: this.filter,
          filterParam: this.user.id,
          limit: this.limit,
          skip: this.skip
        })
        if (users.length === 0) {
          this.noMoreScroll = true
          this.loading = false
        } else {
          if (users.length < this.limit) {
            this.noMoreScroll = true
          }
          this.users = this.users.concat(users)
          setTimeout(() => {
            this.loading = false
          }, 1000)
        }
      }
    }
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 1150px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: flex-end;
  }

  .mobile_design .modal-container {
    width: 100%;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-container {
    height: 460px;
    width: 400px;
    padding: unset;
    border-radius: 5px;
  }

  .main {
    width: 100%;
    overflow: scroll;
    height: 460px;
  }

  .users_title {
    border-bottom: 1px solid #7F828B;
    padding: 0.5em;
  }

  .user {
    display: flex;
    padding: 0.5em;
    align-items: center;
    font-size: 14px;
  }

  .user_image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
  }

  .username {
    flex-grow: 1;
    padding-left: 1em;
    text-align: left;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .usr {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .name {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .mobile_design .main {
    color: black
  }

  .mobile_design .follow_button_wrapper {
    height: 30px;
  }
  .mobile_design .follow_button {
    height: 30px;
    background: white !important;
    color: #b53a44;
    border: 1px solid #b53a44;
    border-radius: 2px;
    width: 90px;
    line-height: 28px;
  }

</style>
