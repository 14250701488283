<template>
  <div v-if="componentData" class="main_containter">
    <DialogComponent />
    <UsersDialogComponent />
    <component :is="getToolbarComponent" />
    <div v-if="!$device.isMobileOrTablet" class="box_container links">
      <div @click="goContactUs()">
        Contact Us
      </div>
      <div @click="goPrivacyPolicy()">
        Privacy Policy
      </div>
      <div v-if="Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Jerusalem'" class="version">
        <div v-if="$store.state.localStorage.currentUser">
          id: {{ $store.state.localStorage.currentUser.id }}
        </div>
      </div>
    </div>
    <div class="main center-content">
      <div v-if="$device.isMobileOrTablet" class="stories">
        <div class="stories_title">
          Buyers Stories & Reviews
        </div>
        <template v-if="stories.length > 0">
          <div v-for="(story, index) in stories" :key="index" class="story">
            <img v-lazy-load :data-src="story.image" @click.stop="showStory(story)">
            <div class="story_user_name">
              {{ getUser(story.userId).name }}
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="(story, index) in [1,2,3,4,5]" :key="index" class="story">
            <img>
            <div class="story_user_name" />
          </div>
        </template>
      </div>
      <!--ListComponent-->
      <component
        :is="getCenterComponent"
        v-if="getCenterComponent"
        ref="list"
        class="feed_wrapper"
        :home-filter-params="homeFilterParams"
        :parent-component-data="getCenterComponentData"
        :on-by-it-click="onByItClick"
      />
      <component
        :is="getRightComponent"
        v-if="getRightComponent"
        class="right_component"
        :class="{'wide_screen': !$device.isMobileOrTablet}"
        @homeFilterChanged="onFilterChanged"
        @navigate="navigate"
      />
    </div>
    <div v-if="itemId" :class="{'show': sheet}" class="aaa">
      <v-bottom-sheet v-model="sheet" class="bbb">
        <v-sheet class="text-center">
          <v-btn text @click="sheet = !sheet">
            close
          </v-btn>
          <BuyItemComponent :entity-id="itemId + ''" @itemAdded="itemAdded" />
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>
<script>
import AbstractComponent from './AbstractComponent'
import DialogComponent from './DialogComponent'
import BuyItemComponent from '~/components/BuyItemComponent'
import utils from '~/mixins/utils'
import UsersDialogComponent from '~/components/UsersDialogComponent'

export default {
  name: 'HomeRootComponent',
  components: { UsersDialogComponent, BuyItemComponent, DialogComponent },
  extends: AbstractComponent,
  mixins: [utils],
  props: {
    onByItClick: {}
  },
  data () {
    return {
      componentData: null,
      homeFilterParams: null,
      itemId: null,
      sheet: false,
      stories: []
    }
  },
  computed: {
    getToolbarComponent () {
      return () =>
        import(
          `../components/${
            this.componentData.components.filter((item) => {
              return item.componentId === 'toolbar'
            })[0].type
          }`
        )
    },
    getLeftComponent () {
      const component = this.componentData.components.filter((item) => {
        return item.componentId === 'left_component'
      })
      if (component && component[0]) {
        return () => import(`../components/${component[0].type}`)
      }
      return null
    },
    getCenterComponent () {
      // ListComponent
      const component = this.componentData.components.filter((item) => {
        return item.componentId === 'center_component'
      })
      if (component && component[0]) {
        return () => import(`../components/${component[0].type}`)
      }
      return null
    },
    getRightComponent () {
      /* RightComponent */
      const component = this.componentData.components.filter((item) => {
        return item.componentId === 'right_component'
      })
      if (component && component[0]) {
        return () => import(`../components/${component[0].type}`)
      }
      return null
    },
    getCenterComponentData () {
      return this.componentData.components.filter((item) => {
        return item.componentId === 'center_component'
      })[0]
    }
  },
  mounted () {
    this.$root.$emit('set_side_bar_context_menu', 'home')
    if (!this.$device.isMobileOrTablet) {
      window.onresize = (event) => {
        const element = document.querySelector('.right_component')
        if (element) {
          element.style.left =
            'calc(' + (window.innerWidth / 2 + 847 / 2 - 273) + 'px)'
        }
      }
    }
    this.initRightComponentPosition()
    // this.$root.$on('background_clicked', () => {
    //   this.sheet = false
    // })
    this.$root.$on('show_item_bottom_sheet', (item, args) => {
      this.itemId = null
      this.itemId = item.id
      this.sheet = true
    })
    this.$root.$on('hide_item_bottom_sheet', (item, args) => {
      setTimeout(() => {
        if (!this.sheet) {
          this.itemId = null
        }
      }, 500)
      this.sheet = false
    })
    if (this.$device.isMobileOrTablet) {
      this.$store.dispatch('api/itemsSelect', {
        filter: 'STORIES'
      })
        .then((stories) => {
          this.stories = stories
        })
        .catch()
    }
  },
  methods: {
    itemAdded (storeId) {
      this.$store.commit('api/setItemStoreId', storeId)
      this.$store.commit('api/setLastPath', this.$route.name)
      this.$router.push({
        path: '/cart'
      })
    },
    showStory (story) {
      this.$refs.list.navigate('stories?story=' + this.encrypt(story.id))
    },
    navigate (path) {
      this.$refs.list.navigate(path)
    },
    onFilterChanged (params) {
      this.homeFilterParams = params
    },
    getUser (userId) {
      return this.$store.state.api.buckets.users[userId]
    },
    goContactUs () {
      this.$router.push({
        path: '/contact-us'
      })
    },
    goPrivacyPolicy () {
      this.$router.push({
        path: '/privacy-policy'
      })
    },
    initRightComponentPosition () {
      if (
        !document.querySelector('.right_component') &&
        !this.$device.isMobileOrTablet
      ) {
        setTimeout(() => {
          if (!document.querySelector('.right_component')) {
            this.initRightComponentPosition()
          } else {
            document.querySelector('.right_component').style.left =
              'calc(' + (window.innerWidth / 2 + 847 / 2 - 273) + 'px)'
          }
        }, 200)
      }
    }
  }
}
</script>
<style scoped>
.main {
  margin: auto;
  flex-grow: 1;
  /*560px - main feed width*/
  /*273px - RightComponent width*/
  /*1em - padding between them*/
  width: calc(560px + 1em + 273px);
  overflow: auto;
  padding-top: calc(70px + 0.5em + 5em);
}

.mobile_design .main {
  width: 100%;
  padding-top: calc(42px + 4em);
}

.feed_wrapper {
  float: left;
  width: 560px;
}

.mobile_design .feed_wrapper {
  width: 100%;
  order: 3;
  /*margin-top: calc(-18.5em + 1px);*/
}

.header-sidebar h4 {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.header-sidebar img {
  width: 90px;
  height: 90px;
}

> button {
  margin-left: 10px;
}

> button {
  border: 0px solid rgba(0, 0, 0, 0) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
  border-radius: 0px !important;
}

.mf_item_properties > div {
  text-align: left;
}

.right_component {
  top: calc(49px + 50px + 1em + 4em);
  width: 273px;
  position: fixed;
  height: calc(100% - 127px - 1em);
  overflow: scroll;
}

.mobile_design .right_component {
  top: calc(49px);
  position: unset;
  order: 1;
  width: 100vh;
}

.mobile_design .center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*margin-top: calc(49px - 1em);*/
}

.v-dialog__container {
  display: block !important;
}

.mobile_design .stories {
  order: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1px;
  background-color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  overflow-x: scroll;
}

.stories_title {
  position: absolute;
  padding-left: 1em;
  font-size: 16px;
  background-color: white;
  width: 100%;
}

.mobile_design .stories img {
  display: block;
  float: left;
  width: 70px !important;
  min-width: 70px !important;
  height: 70px;
  border-radius: 50%;
  border: 3px solid #ed4956;
  padding: 3px;
  background: white;
  margin-left: 10px;
}

.story {
  text-align: center;
  margin-top: 1.7em;
}

.story_user_name {
  white-space: nowrap;
  width: 70px !important;
  min-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: center;
  padding-left: 16px;
  padding-top: 0.2em;
  color: #4a4a4a;
}

.links {
  position: fixed;
  padding: 1em;
  display: flex;
  background: unset;
  flex-direction: row;
  left: 0;
  bottom: 0;
}
.links div {
  margin-right: 1em;
  cursor: pointer;
}
</style>
